@import "button.css";
@import "page-footer.css";
@import "nav-bar.css";

@import "layouts/index.css";
@import "angular.css";

*{
    font-family:"Roboto";
    margin:0;
    padding:0;
    box-sizing:border-box;
}