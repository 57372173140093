.nav-bar-container {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;

  box-shadow: rgb(90 95 102) 0-1.5px 0 inset;

  z-index: 300;
  background-color: var(--primary-color);
}

.nav-bar {
  flex: 1;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  /* responsive */

  height: 60px;
  max-width: 1200px;

  padding: 0 24px;
  margin: 0;
}

.nav-bar-brand {
  display: flex;
  align-items: center;

  height: 100%;

  /* responsive */

  margin-right: 64px;
}

.nav-bar-link {
  display: flex;
  align-items: center;

  height: 100%;
}

.nav-bar-logo {
  height: 2rem;
}

.nav-bar-tabs {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-bar-tab {
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-right: 24px;

  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.nav-bar-tab:last-child {
  margin-right: 0;
}

.nav-bar-tab--active {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: var(--indigo);

  /* responsive */

  text-decoration-thickness: 4px;
  text-underline-offset: 8px;
}

.nav-bar-tab:hover {
  color: var(--white);
}

.nav-bar-buttons {
  display: flex;
  margin-left: 24px;
}

@media only screen and (max-width: 640px) and (hover: none) {
  .mobile-nav-bar-tab:hover {
    color: var(--white);
  }

  .mobile-nav-bar-tab--active:hover {
    color: var(--white);
  }
}

@media only screen and (max-width: 640px) {
  .nav-bar-container {
    display: none;
  }

  .nav-bar-tab {
    font-size: 1.5rem;
  }

  .nav-bar {
    height: 6.4rem;

    padding: 0.8rem 1.6rem;
  }

  .nav-bar-brand {
    display: flex;
    align-items: center;

    margin-right: 1.6rem;
  }

  .nav-bar-logo {
    height: 2rem;
  }

  .nav-bar-tab--active {
    text-decoration-line: none;
    color: var(--pink);
  }

  .nav-bar-label {
    display: none;
  }

  .nav-bar-buttons {
    margin-left: 24px;
  }
}

@media only screen and (max-width: 340px) {
  .nav-bar-tab {
    font-size: 1.3rem;
  }

  .nav-bar {
    height: 6.4rem;

    padding: 0.8rem 1.6rem;
  }

  .nav-bar-brand {
    display: flex;
    align-items: center;

    margin-right: 1.6rem;
  }

  .nav-bar-logo {
    height: 2rem;
  }

  .nav-bar-tab--active {
    text-decoration-line: none;
    color: var(--pink);
  }

  .nav-bar-buttons {
    margin-left: 24px;
  }
}
