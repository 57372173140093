.button {
  border: 0;
  border-radius: 5px;

  font-family: var(--font-primary);
  font-weight: 600;
  color: var(--white);

  cursor: pointer;
  user-select: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;

  /* responsive */

  min-width: 10rem;
  padding: 1.6rem 1.6rem;

  font-size: 1rem;
  line-height: 1rem;
}

.button--compact {
  /* responsive */

  padding: 1rem 1.6rem;

  font-size: 1.6rem;
  line-height: 1.6rem;
}

.button--primary {
  background-color: var(--indigo);
}

.button--primary:hover {
  background: rgba(99, 93, 255, 0.85);
}

.button--secondary {
  background-color: var(--white);
  color: var(--black);
}

.button--secondary:hover {
  background: rgba(255, 255, 255, 0.85);
}

.btn-login,
.btn-log-out {
  min-width: 8.4rem;
  border-radius: 5px;
  border: 0.1rem solid var(--secondary-color);
  color: var(--primary-color);
  background: var(--secondary-color);
  width: 50%;
  font-size: 1rem;

  margin: 1.6rem;

  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  padding: 0.6rem 0;

  text-align: center;

  cursor: pointer;
  user-select: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;
}

.btn-sign-up {
  min-width: 8.4rem;

  border: 0.1rem solid var(--white);
  color: var(--white);
  background-color: transparent;
  width: 50%;
  font-size: 1rem;

  margin-right: 1.6rem;

  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  padding: 0.8rem 0;
  border-radius: 5px;
  text-align: center;

  cursor: pointer;
  user-select: none;
}

.btn-login:last-child,
.btn-logout:last-child,
.btn-sign-up:last-child {
  margin-right: 0;
}


