.page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
  height: 100%;
  width: 100%;
}

.page-layout-content {
  flex: 1;
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  max-width: 120rem;
  width: 100%;
}

.page-layout-blank {
    padding-top: 30vh;
    padding-bottom:10vh;
    padding-left: 20vw;
    padding-right: 20vw;

    align-items: center;
    vertical-align: middle;
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 640px) {
  .page-layout-content {
    margin-top: 6.4rem;
  }
}
